import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import barbershopService from "../../../services/BarbershopService";
import NavlogComponent from "../../../components/NavlogComponent";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { storageUrl } from "../../../config";

const BarbershopBarbersPage = () => {
  const { id } = useParams();
  const [barbers, setBarbers] = useState([]);

  useEffect(() => {
    const fetchBarbers = async () => {
      try {
        const response = await barbershopService.listBarbers(id);
        setBarbers(response.barbers);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Erro ao carregar barbeiros.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });
      }
    };

    fetchBarbers();
  }, [id]);

  const handleAddBarber = async () => {
    const { value: barber_email } = await Swal.fire({
      title: "Adicionar Barbeiro",
      input: "email",
      inputLabel: "Insira o email do barbeiro",
      inputPlaceholder: "email@exemplo.com",
      showCancelButton: true,
      confirmButtonText: "Adicionar",
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa digitar um email!";
        }
      },
      customClass: {
        popup: "custom-swal",
        title: "custom-swal-title",
        content: "custom-swal-text",
      },
    });

    if (barber_email) {
      try {
        const response = await barbershopService.addBarber(id, barber_email);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: response.message,
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });

        const updatedBarbers = await barbershopService.listBarbers(id);
        setBarbers(updatedBarbers.barbers);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error.message || error,
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });
      }
    }
  };

  const handleRemoveBarber = (barberId) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, remover!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "custom-swal",
        title: "custom-swal-title",
        content: "custom-swal-text",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const message = await barbershopService.removeBarber(id, barberId);
          setBarbers((prevBarbers) =>
            prevBarbers.filter((barber) => barber.id !== barberId)
          );
          Swal.fire({
            title: "Removido!",
            text: message,
            icon: "success",
            customClass: {
              popup: "custom-swal",
              title: "custom-swal-title",
              content: "custom-swal-text",
            },
          });
        } catch (error) {
          Swal.fire({
            title: "Erro!",
            text: error,
            icon: "error",
            customClass: {
              popup: "custom-swal",
              title: "custom-swal-title",
              content: "custom-swal-text",
            },
          });
        }
      }
    });
  };

  return (
    <>
      <NavlogComponent />
      <Container>
        <p className="labeltitle h4 text-center text-uppercase">Barbeiros associados</p>
        <Button variant="primary" onClick={handleAddBarber} className="mb-3">
          Associar novo Barbeiro
        </Button>
        <Row className="justify-content-center mt-4">
          <Col md={12}>
            <Card>
              <Card.Body>
                {barbers.length > 0 ? (
                  <Row>
                    {barbers.map((barber) => {
                      const avatarUrl = barber.avatar
                        ? `${storageUrl}/${barber.avatar}`
                        : "/images/user.png";

                      return (
                        <Col xs={12} sm={6} md={4} key={barber.id} className="mb-3">
                          <Card className="card-barbershop-show">
                            <div
                              className="background-image"
                              style={{
                                backgroundImage: `url('${avatarUrl}')`,
                              }}
                            />
                            <Link to={`/user/${barber.user_name}`} style={{ textDecoration: "none" }}>
                              <img
                                src={avatarUrl}
                                className="rounded-circle img-logo-barbershop-show"
                                style={{ margin: '0 auto', display: 'block' }}
                                alt={barber.first_name}
                              />
                            </Link>
                            <Card.Body>
                              <Link to={`/user/${barber.user_name}`} style={{ textDecoration: "none" }}>
                                <p className="labeltitle h6 text-center text-uppercase">{barber.first_name}</p>
                              </Link>
                              <Button variant="danger" onClick={() => handleRemoveBarber(barber.id)}>
                                Remover
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <ListGroup.Item>Nenhum barbeiro encontrado.</ListGroup.Item>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BarbershopBarbersPage;
