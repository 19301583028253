import axios from "axios";
import { apiBaseUrl } from "../config";

const apiServiceUrl = "barber";

const BarberService = {
  getToken: () => localStorage.getItem("token"),

  store: async (formData) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/${apiServiceUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BarberService.getToken()}`,
          },
        }
      );
      return response.data.message;
    } catch (error) {
      // Aqui lançamos diretamente a mensagem da API
      if (error.response && error.response.data.description) {
        throw new Error(error.response.data.description.join(", ")); // Ajuste para lidar com o array
      } else {
        throw new Error("Erro durante o registro. Por favor, tente novamente.");
      }
    }
  },
  
  listAll: async () => {
    try {
      const headers = {
        Authorization: `Bearer ${BarberService.getToken()}`,
      };

      const response = await axios.get(`${apiBaseUrl}/${apiServiceUrl}`, {
        headers,
      });

      if (response.status === 200) {
        return response.data; // Retorna a lista de barbeiros
      } else {
        throw new Error(
          "Erro ao obter a lista de barbeiros. Por favor, tente novamente."
        );
      }
    } catch (error) {
      console.error(error);
      throw new Error(
        "Erro ao obter a lista de barbeiros. Por favor, tente novamente."
      );
    }
  },
  
  show: async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${BarberService.getToken()}`,
      };

      const response = await axios.get(
        `${apiBaseUrl}/${apiServiceUrl}/show/${id}`,
        {
          headers,
        }
      );

      if (response.status === 200) {
        return response.data; // Retorna os dados do barbeiro
      } else {
        throw new Error(
          "Erro ao obter os dados do barbeiro. Por favor, tente novamente."
        );
      }
    } catch (error) {
      console.error(error);
      throw new Error(
        "Erro ao obter os dados do barbeiro. Por favor, tente novamente."
      );
    }
  },

  update: async (id, formData) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/${apiServiceUrl}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BarberService.getToken()}`,
          },
        }
      );
      return response.data.message; // Retorna a mensagem de sucesso
    } catch (error) {
      if (error.response && error.response.data.errors) {
        throw error.response.data.errors; // Retorna erros de validação
      } else {
        throw new Error("Erro ao atualizar o barbeiro. Por favor, tente novamente.");
      }
    }
  },
  
  destroy: async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${BarberService.getToken()}`,
      };

      const response = await axios.delete(
        `${apiBaseUrl}/${apiServiceUrl}/${id}`,
        {
          headers,
        }
      );

      if (response.status === 200) {
        return response.data.message;
      } else {
        throw new Error(
          "Erro ao deletar barbeiro. Por favor, tente novamente."
        );
      }
    } catch (error) {
      if (error.response) {
        throw new Error(
          error.response.data.error || "Erro ao se conectar ao servidor."
        );
      } else {
        throw new Error("Erro ao se conectar ao servidor.");
      }
    }
  },
};

export default BarberService;
