import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import barbershopService from "../../../services/BarbershopService";
import NavlogComponent from "../../../components/NavlogComponent";
import { useParams } from "react-router-dom";
import { storageUrl } from "../../../config";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const BarbershopShowPage = () => {
  const { id } = useParams();
  const [barbershop, setBarbershop] = useState(null);
  const [barbers, setBarbers] = useState([]); // Estado para armazenar barbeiros

  useEffect(() => {
    const fetchBarbershop = async () => {
      try {
        const response = await barbershopService.show(id);
        setBarbershop(response.barbershop);
        console.log(response);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "Erro ao carregar informações da barbearia.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,
        });
      }
    };

    fetchBarbershop();
  }, [id]);

  useEffect(() => {
    const fetchBarbers = async () => {
      try {
        const response = await barbershopService.listBarbers(id);
        setBarbers(response.barbers);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Erro ao carregar barbeiros.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });
      }
    };

    fetchBarbers();
  }, [id]);

  if (!barbershop) {
    return <p>Carregando...</p>; // Exibe uma mensagem enquanto os dados estão sendo carregados
  }

  return (
    <>
      <NavlogComponent />
     
      <Card>
      <Card.Body>
    <div className="text-center">
      <Row>
    <Col md={6}> <div
                                        className="background-image"
                                        style={{
                                            backgroundImage: `url('${storageUrl}/${barbershop.logo}')`,
                                        }}
                                    />
      <img
        src={barbershop.logo ? `${storageUrl}/${barbershop.logo}` : "/images/barbershoplogo.png"}
        alt="Preview da Logo"
        className="img-fluid rounded-circle img-logo-barbershop"
      /> 
      </Col>
      <Col md={6}> <p className="labeltitle h2 text-center text-uppercase">
      {barbershop.name}
    </p>
      </Col>
      </Row>
      </div>
      </Card.Body></Card>
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col md={12}>
                <p className="text-center">{barbershop.description}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <p className="labeltitle h2 text-center text-uppercase">
          Serviços
        </p>

        {/* Adicione aqui a lista de serviços, se necessário */}

        <p className="labeltitle h2 text-center text-uppercase">
          Barbeiros
        </p>
        
        <Row>
          {barbers.length > 0 ? (
            barbers.map(barber => (
              <Col xs={12} sm={6} md={4} key={barber.id} className="mb-3">
              <Card className="card-barbershop-show">
                  <div
                      className="background-image"
                      style={{
                          backgroundImage: `url('${storageUrl}/${barber.avatar}')`,
                      }}
                  />
                  <Link
                      to={`/user/${barber.user_name}`}
                      style={{ textDecoration: "none" }}
                  >
                    <img
      src={barber.avatar ? `${storageUrl}/${barber.avatar}` : '/images/user.png'}
      className="rounded-circle img-logo-barbershop-show"
      style={{ margin: '0 auto', display: 'block' }}
      alt={barber.first_name}
  />
  
                  </Link>
                  <Card.Body>
                      <Link
                          to={`/user/${barber.user_name}`}
                          style={{ textDecoration: "none" }}
                      >
                          <p className="labeltitle h6 text-center text-uppercase">{barber.first_name}</p>
                      </Link>
                  </Card.Body>
              </Card>
          </Col>
            ))
          ) : (
            <Col md={12} className="text-center">
              <p>Nenhum barbeiro disponível no momento.</p>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default BarbershopShowPage;
