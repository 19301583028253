import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import barbershopService from "../../../services/BarbershopService";
import NavlogComponent from "../../../components/NavlogComponent";
import { useParams } from "react-router-dom";
import { storageUrl } from "../../../config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const BarbershopDashboardPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    logo: null,
    background_image: null,
    name: "",
    email: "",
    phone: "",
    description: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    website: "",
    opening_hours: "",
    facebook: "",
    instagram: "",
  });

  useEffect(() => {
    const fetchBarbershop = async () => {
      try {
        const response = await barbershopService.show(id);
        const barbershop = response.barbershop;
        setFormData({
          ...formData,
          ...barbershop,
        });

      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "Erro ao carregar informações da barbearia.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,  customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });
      }
    };

    fetchBarbershop();
  }, [id]);

  return (
    <>
      <NavlogComponent />
      <p className="labeltitle h2 text-center text-uppercase">
        {formData.name}
      </p>
      <Container>
      <Card>
  <Card.Body>
    <Row className="d-flex align-items-center justify-content-center">
      {/* Coluna para a imagem, centralizada */}
      <Col md={4} className="d-flex justify-content-center">
        <img
          src={`${storageUrl}/${formData.logo}`}
          className="rounded-circle img-fluid img-logo-barbershop-dashboard m-2"
          alt={formData.name}
          style={{ maxWidth: "150px", height: "auto" }}
        />
      </Col>

      {/* Coluna para os botões, centralizados */}
      <Col md={4} className="d-flex flex-column align-items-center">
      <Button
                  variant="primary m-2 w-50"
                  onClick={() => navigate(`/barbershop/barbers/${id}`)} // Adicione a navegação aqui
                >
                  Barbeiros
                </Button>
        <Button variant="primary m-2 w-50" type="submit">
          Serviços
        </Button>
        <Button variant="primary m-2 w-50" type="submit">
          Produtos
        </Button>
      </Col>     <Col md={4} className="d-flex flex-column align-items-center">
        <Button variant="primary m-2 w-50" type="submit">
        Controle
        </Button>
        <Button variant="primary m-2 w-50" type="submit">
          Agendamentos
        </Button>
        <Button variant="primary m-2 w-50" type="submit">
          Caixa
        </Button>
      </Col>
    </Row>
  </Card.Body>
</Card>

      </Container>
    </>
  );
};

export default BarbershopDashboardPage;
