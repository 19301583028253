import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2"; // Importa o SweetAlert2
import authService from "../../services/AuthService";
import userService from "../../services/UserService";
import NavlogComponent from "../../components/NavlogComponent";
import { storageUrl } from "../../config";

const UserEditPage = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    avatar: null,
    cpf: "",
    address: "",
    phone: "",
    city: "",
    uf: "",
    postal_code: "",
    birthdate: "",
    gender: "",
    marital_status: "",
    occupation: "",
    about: "",
    favorite_artist: "",
    favorite_genre: "",
  });
  const [error, setError] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await authService.me();
        setUser(userData);
        setFormData({
          first_name: userData.first_name,
          last_name: userData.last_name,
          cpf: userData.cpf,
          address: userData.address,
          phone: userData.phone,
          city: userData.city,
          uf: userData.uf,
          postal_code: userData.postal_code,
          birthdate: userData.birthdate,
          gender: userData.gender,
          marital_status: userData.marital_status,
          occupation: userData.occupation,
          about: userData.about,
          favorite_artist: userData.favorite_artist,
          favorite_genre: userData.favorite_genre,
          avatar: userData.avatar,
        });
      } catch (error) {
        console.error(error);
        setError("Erro ao carregar os dados do usuário. Por favor, tente novamente.");
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImage(event.target.result);
    };
    reader.readAsDataURL(file);

    setFormData({
      ...formData,
      avatar: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário
    try {
      const modifiedData = {};
      for (const key in formData) {
        if (formData[key] !== user[key]) {
          modifiedData[key] = formData[key];
        }
      }
      if (Object.keys(modifiedData).length === 0) {
        return;
      }
      const success = await userService.update(user.id, modifiedData);
      if (success) {
        const updatedUserData = await authService.me();
        Swal.fire({
          title: "Sucesso",
          text: "Dados atualizados com sucesso",
          icon: "success",
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        }); // Alerta SweetAlert
        setUser(updatedUserData);
      }
    } catch (error) {
      console.error(error.data);
      Swal.fire({
        title: "Erro",
        text: error.message,
        icon: "error",
        customClass: {
          popup: "custom-swal",
          title: "custom-swal-title",
          content: "custom-swal-text",
        },
      }); // Alerta de erro SweetAlert
      setError("Erro ao atualizar o usuário. Por favor, tente novamente.");
    }
  };

  return (
    <>
      <NavlogComponent />
      <Container fluid>
        <Row className="justify-content-left m-4">
          <Col md={4}>
            <Card>
              <Card.Body>
                {error && (
                  Swal.fire({
                    title: "Erro",
                    text: error,
                    icon: "error",
                    customClass: {
                      popup: "custom-swal",
                      title: "custom-swal-title",
                      content: "custom-swal-text",
                    },
                  }) // Alerta de erro SweetAlert
                )}

                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formAvatar">
                    <div className="avatar-container d-flex justify-content-center">
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="Preview Avatar"
                          className="avatar"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : user && user.avatar ? (
                        <img
                          src={
                            user.avatar.startsWith("http")
                              ? user.avatar
                              : `${storageUrl}/${user.avatar}`
                          }
                          alt="Avatar"
                          className="avatar"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <img
                          src="/images/loadingimage.gif"
                          alt="Default Avatar"
                          className="avatar"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                    <input
                      id="avatarInput"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="secondary"
                      className="w-100 mt-4"
                      onClick={() =>
                        document.getElementById("avatarInput").click()
                      }
                    >
                      Alterar Foto
                    </Button>
                  </Form.Group>

                  <Form.Group controlId="formFirstName" className="mt-4">
                    <Form.Control
                      type="text"
                      placeholder="Nome"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formLastName" className="mt-4">
                    <Form.Control
                      type="text"
                      placeholder="Sobrenome"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formPhone" className="mt-4">
                    <Form.Control
                      type="text"
                      placeholder="Telefone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formCPF" className="mt-4">
                    <Form.Control
                      type="text"
                      placeholder="CPF"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formBirthdate" className="mt-4">
                    <Form.Control
                      type="date"
                      placeholder="Data de nascimento"
                      name="birthdate"
                      value={formData.birthdate}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit" // O botão agora submete o formulário
                    className="m-4"
                  >
                    Salvar Alterações
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserEditPage;
