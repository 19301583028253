import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import authService from "../../services/AuthService";
import './css/Auth.css'; // Estilos adicionais

const PasswordEmailPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await authService.passwordEmail(email);

      Swal.fire({
        title: 'Sucesso!',
        text: response.message || 'Código enviado para o e-mail com sucesso!',
        icon: 'success',
        confirmButtonText: 'Recebi o Código',
        customClass: {
          popup: 'custom-swal',
          title: 'custom-swal-title',
          content: 'custom-swal-text',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/password-reset';
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error.message || 'Ocorreu um erro inesperado.',
        icon: 'error',
        confirmButtonText: 'Tente Novamente',
        customClass: {
          popup: 'custom-swal',
          title: 'custom-swal-title',
          content: 'custom-swal-text',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="login-container" style={{ height: '100vh' }}>
      <Row className="vh-100">
        <Col
          md={12}
          className="d-flex align-items-center justify-content-center position-relative"
          style={{
            background: `url('/images/background-2.png') no-repeat center center`,
            backgroundSize: 'cover',
            height: '100vh',
          }}
        >
          <Card className="login-card">
            <Card.Body>
              <div className="text-center mb-4">
                <img
                  src="/images/logo.png"
                  alt="Logo"
                  className="logo rounded-circle "
                  style={{ width: "120px", height: "120px" }}
                />
              </div>
              <Card.Title className="text-center mb-4">Recuperar Senha</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {loading ? 'Enviando...' : 'Enviar Código'}
                </Button>
                <p className="forgot-password text-center mt-3">
                  Voltar para <a href="/login" className="auth-link">Login</a>
                </p>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordEmailPage;
