import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'; // Importando Button
import Swal from 'sweetalert2'; 
import barbershopService from '../services/BarbershopService'; 
import barberService from '../services/BarberService'; 
import NavlogComponent from "../components/NavlogComponent";
import { storageUrl } from "../config";
import { Link } from "react-router-dom";

const Dashboard = () => {
    // Inicializando como arrays vazios
    const [barbershops, setBarbershops] = useState([]); 
    const [barbers, setBarbers] = useState([]); 

    useEffect(() => {
        const fetchBarbershops = async () => {
            try {
                const response = await barbershopService.listAll();
                // Verifique se a resposta é válida antes de definir o estado
                if (response && response.barbershops && response.barbershops.data) {
                    setBarbershops(response.barbershops.data);
                } else {
                    setBarbershops([]); // Reseta para array vazio se a resposta não for válida
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao carregar barbearias.',
                    customClass: {
                        popup: "custom-swal",
                        title: "custom-swal-title",
                        content: "custom-swal-text",
                    },
                });
                setBarbershops([]); // Reseta para array vazio em caso de erro
            }
        };

        fetchBarbershops();
    }, []);

    useEffect(() => {
        const fetchBarbers = async () => {
            try {
                const response = await barberService.listAll();
                // Verifique se a resposta é válida antes de definir o estado
                if (response && response.barbers && response.barbers.data) {
                    setBarbers(response.barbers.data);
                } else {
                    setBarbers([]); // Reseta para array vazio se a resposta não for válida
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao carregar barbeiros.',
                    customClass: {
                        popup: "custom-swal",
                        title: "custom-swal-title",
                        content: "custom-swal-text",
                    },
                });
                setBarbers([]); // Reseta para array vazio em caso de erro
            }
        };

        fetchBarbers();
    }, []);

    return (
        <>
            <NavlogComponent />
            <Container fluid className="main-content">
                <p className="labeltitle h4 text-center text-uppercase">Barbearias</p>
                <Row>
                    {barbershops.length === 0 ? (
                        <Col xs={12} className="text-center mb-3">
                           <Link to="/barbershop/create">
                        <Button variant="primary">
                            <i className="bi bi-plus-circle  m-2 "></i>
                            Adicionar nova barbearia
                        </Button>
                    </Link>
                        </Col>
                    ) : (
                        barbershops.map((barbershop) => (
                            <Col xs={12} sm={6} md={4} key={barbershop.id} className="mb-3">
                                <Card className="card-barbershop-show">
                                    <div
                                        className="background-image"
                                        style={{
                                            backgroundImage: `url('${storageUrl}/${barbershop.logo}')`,
                                        }}
                                    />
                                    <Link
                                        to={`/barbershop/show/${barbershop.id}`}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img
                                            src={`${storageUrl}/${barbershop.logo}`}
                                            className="rounded-circle img-logo-barbershop-show"
                                            style={{ margin: '0 auto', display: 'block' }}
                                            alt={barbershop.name}
                                        />
                                    </Link>
                                    <Card.Body>
                                        <Link
                                            to={`/barbershop/show/${barbershop.id}`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <p className="labeltitle h6 text-center text-uppercase">{barbershop.name}</p>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>

                <p className="labeltitle h4 text-center text-uppercase">Barbeiros</p>
                <Row>
                {barbers.map((barber) => {
    return (
        <Col xs={12} sm={6} md={4} key={barber.id} className="mb-3">
            <Card className="card-barbershop-show">
                <div
                    className="background-image"
                    style={{
                        backgroundImage: `url('${storageUrl}/${barber.avatar}')`,
                    }}
                />
                <Link
                    to={`/user/${barber.user_name}`}
                    style={{ textDecoration: "none" }}
                >
                  <img
    src={barber.avatar ? `${storageUrl}/${barber.avatar}` : 'images/user.png'}
    className="rounded-circle img-logo-barbershop-show"
    style={{ margin: '0 auto', display: 'block' }}
    alt={barber.first_name}
/>

                </Link>
                <Card.Body>
                    <Link
                        to={`/user/${barber.user_name}`}
                        style={{ textDecoration: "none" }}
                    >
                        <p className="labeltitle h6 text-center text-uppercase">{barber.first_name}</p>
                    </Link>
                </Card.Body>
            </Card>
        </Col>
    );
})}

                </Row>
            </Container>
        </>
    );
};

export default Dashboard;
