import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Auth
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import EmailVerifyPage from "./pages/auth/EmailVerifyPage";
import LogoutPage from "./pages/auth/LogoutPage";
import PasswordEmailPage from "./pages/auth/PasswordEmailPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import PasswordPage from "./pages/auth/PasswordPage";
import DashboardPage from "./pages/DashboardPage";

// Administrativo
// User
import UserListPage from "./pages/admin/user/UserListPage";
import UserCreatePage from "./pages/admin/user/UserCreatePage";
// Profile
import ProfileCreatePage from "./pages/admin/profile/ProfileCreatePage";
import ProfileListPage from "./pages/admin/profile/ProfileListPage";
import ProfileUpdatePage from "./pages/admin/profile/ProfileUpdatePage";


//Corporativo
// Item
import ItemCreatePage from "./pages/item/ItemCreatePage";
import ItemUpdatePage from "./pages/item/ItemUpdatePage"; 
import ItemListPage from "./pages/item/ItemListPage"; 
import ItemViewPage from "./pages/item/ItemViewPage"; 
// Barbershop
import BarbershopCreatePage from "./pages/corp/barbershop/BarbershopCreatePage";
import BarbershopShowPage from "./pages/corp/barbershop/BarbershopShowPage";
import BarbershopUpdatePage from "./pages/corp/barbershop/BarbershopUpdatePage"; 
import BarbershopDashboardPage from "./pages/corp/barbershop/BarbershopDashboardPage"; 
import BarbershopListPage from "./pages/corp/barbershop/BarbershopListPage"; 
import BarbershopBarbersPage from "./pages/corp/barbershop/BarbershopBarbersPage"; 

// User
import UserViewPage from "./pages/user/UserViewPage";
import UserEditPage from "./pages/user/UserEditPage";

import LoadingComponent from "./components/LoadingComponent";
import authService from "./services/AuthService";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await authService.me();
        setUser(userData);
      } catch (error) {
        setUser(null);
        localStorage.removeItem("token"); // Remove o token do localStorage em caso de erro
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return <LoadingComponent />;
  }

  const protectedRoute = (element) => {
    if (user) {
      if (user.email_verified_at) {
        return element;
      } else {
        return <Navigate to="/email-verify" />;
      }
    }
    return <Navigate to="/login" />;
  };

  const emailVerifiedRoute = (element) => {
    if (user) {
      if (!user.email_verified_at) {
        return element;
      } else {
        return <Navigate to="/dashboard" />;
      }
    }
    return <Navigate to="/login" />;
  };

  const restrictedRoute = (element) => {
    if (!user) {
      return element;
    } else {
      return <Navigate to="/dashboard" />;
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/register" element={restrictedRoute(<RegisterPage />)} />
        <Route path="/login" element={restrictedRoute(<LoginPage />)} />
        <Route path="/password-email" element={restrictedRoute(<PasswordEmailPage />)} />
        <Route path="/password-reset" element={restrictedRoute(<PasswordResetPage />)} />
        <Route path="/email-verify" element={emailVerifiedRoute(<EmailVerifyPage />)} />
        <Route path="/password" element={protectedRoute(<PasswordPage />)} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/*" element={<Navigate to="/login" />} />
        <Route path="/dashboard" element={protectedRoute(<DashboardPage />)} />
        
        <Route path="/user/edit" element={protectedRoute(<UserEditPage />)} />
        <Route path="/user/list" element={protectedRoute(<UserListPage />)} />
        <Route path="/user/create" element={protectedRoute(<UserCreatePage />)} /> 
        <Route path="/user/:userName" element={protectedRoute(<UserViewPage />)} />

        <Route path="/profile/create" element={protectedRoute(<ProfileCreatePage />)} />
        <Route path="/profile/list" element={protectedRoute(<ProfileListPage />)} />
        <Route path="/profile/update/:id" element={protectedRoute(<ProfileUpdatePage />)} />
       
               
        <Route path="/item" element={protectedRoute(<ItemListPage />)} />
        <Route path="/item/create" element={protectedRoute(<ItemCreatePage />)} />
        <Route path="/item/update/:id" element={protectedRoute(<ItemUpdatePage />)} />
        <Route path="/item/:id" element={protectedRoute(<ItemViewPage />)} />

        <Route path="/barbershop" element={protectedRoute(<BarbershopListPage />)} />
        <Route path="/barbershop/create" element={protectedRoute(<BarbershopCreatePage />)} />
        <Route path="/barbershop/show/:id" element={protectedRoute(<BarbershopShowPage />)} />
        <Route path="/barbershop/update/:id" element={protectedRoute(<BarbershopUpdatePage />)} />
        <Route path="/barbershop/dashboard/:id" element={protectedRoute(<BarbershopDashboardPage />)} />
        <Route path="/barbershop/barbers/:id" element={protectedRoute(<BarbershopBarbersPage />)} />
    
      </Routes>
    </Router>
  );
};

export default App;
