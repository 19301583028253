import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavlogComponent from "../../../components/NavlogComponent";
import ProcessingIndicatorComponent from '../../../components/ProcessingIndicatorComponent';
import Swal from "sweetalert2";
import axios from "axios";
import { apiBaseUrl, storageUrl } from "../../../config";

const BarbershopListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [barbershops, setBarbershops] = useState([]);

  const fetchBarbershops = async () => {
    setMessages(['Carregando barbearias...']);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      const response = await axios.get(`${apiBaseUrl}/barbershop/user`, { headers });

      setBarbershops(response.data.barbershops.data);
    } catch (error) {
      console.error("Erro ao carregar barbearias:", error.response?.data);
      setMessages(['Ocorreu um erro ao carregar as barbearias. Tente novamente mais tarde.']);
      Swal.fire({
        title: "Erro",
        text: error.response?.data?.error || "Não foi possível carregar as barbearias. Tente novamente mais tarde.",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          popup: 'custom-swal',
          title: 'custom-swal-title',
          content: 'custom-swal-text',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBarbershops();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Você tem certeza?',
        text: 'Esta ação não pode ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          popup: "custom-swal",
          title: "custom-swal-title",
          content: "custom-swal-text",
        },
      });

      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        // Requisição de deleção para a API
        await axios.delete(`${apiBaseUrl}/barbershop/${id}`, { headers });

        Swal.fire({
          title: 'Deletado!',
          text: 'A barbearia foi deletada com sucesso.',
          icon: 'success',
          customClass: {
            popup: "custom-swal",
            title: "custom-swal-title",
            content: "custom-swal-text",
          },
        });

        // Atualiza a lista de barbearias removendo a barbearia deletada
        setBarbershops(barbershops.filter(barbershop => barbershop.id !== id));
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar barbearia.',
        customClass: {
          popup: "custom-swal",
          title: "custom-swal-title",
          content: "custom-swal-text",
        },
      });
    }
  };

  return (
    <>
      <NavlogComponent />
      <p className="labeltitle h2 text-center text-uppercase">
      Minhas barbearias
      </p>
      <Container>
        {isLoading ? (
          <ProcessingIndicatorComponent messages={messages} />
        ) : (
          <Row>
                <Link to={`/barbershop/create`}> 
                <Button   className="btn btn-primary w-100">
              Cadastrar nova barbearia
                  </Button>
                  
                  </Link>
           
                 
            {barbershops.map((barbershop) => (
              <Col xs={12} sm={12} md={12} key={barbershop.id} className="mb-3">
                <Card className="card-barbershop-show">
                  <div className="background-image" style={{ backgroundImage: `url('${storageUrl}/${barbershop.background_image}')` }} />
                  <Link to={`/barbershop/${barbershop.slug}`} style={{ textDecoration: "none" }}>
                    <img src={`${storageUrl}/${barbershop.logo}`} className="rounded-circle img-logo-barbershop-show" style={{ margin: '0 auto', display: 'block' }} alt={barbershop.name} />
                  </Link>
                  <Card.Body>
                    <Link to={`/barbershop/${barbershop.slug}`} style={{ textDecoration: "none" }}>
                      <p className="labeltitle h6 text-center text-uppercase">{barbershop.name}</p>
                    </Link>
                    <Link to={`/barbershop/dashboard/${barbershop.id}`} className="btn btn-success m-2"> Dashboard </Link>
                    <Link to={`/barbershop/update/${barbershop.id}`} className="btn btn-success m-2"> Editar </Link>
                    <button className="btn btn-danger m-2" onClick={() => handleDelete(barbershop.id)}> Deletar </button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};

export default BarbershopListPage;
